import * as React from "react"
import { useState, useEffect } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as style from "../../styles/recruit_data.module.scss"
import { PieChart } from "react-minimal-pie-chart"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import NumberEasing from "react-number-easing"

const Arrowsdata = () => {
  const [numb0, setNumb0] = useState("0")
  const [numb1, setNumb1] = useState("0")
  // const [ numb2, setNumb2 ] = useState('0');

  const [pie01, setPie01] = useState(0)
  const pieData1 = [
    { color: "url(#gradient1)", title: "アパレル事業", value: 30 },
    { color: "url(#gradient2)", title: "ジュエリー", value: 10 },
    { color: "url(#gradient3)", title: "家電", value: 10 },
    { color: "url(#gradient4)", title: "雑貨", value: 10 },
    { color: "url(#gradient5)", title: "EC事業", value: 40 },
  ]

  const [pie02, setPie02] = useState(0)
  const pieData2 = [
    { color: "url(#gradient1)", title: "シニア", value: 50 },
    { color: "url(#gradient2)", title: "ミドル", value: 30 },
    { color: "url(#gradient3)", title: "ヤング", value: 20 },
  ]

  gsap.registerPlugin(ScrollTrigger)
  useEffect(e => {
    gsap.to("#salesAmout", {
      scrollTrigger: {
        trigger: "#salesAmout",
        start: "top bottom",
        end: "bottom top",
        onEnter: () => {
          setNumb0(1)
        },
      },
    })
    gsap.to("#salesAmout", {
      scrollTrigger: {
        trigger: "#salesAmout",
        start: "top bottom",
        end: "bottom top",
        onEnter: () => {
          setNumb1(190)
        },
      },
    })
    // gsap.to(
    //   "#salesAmout",
    //   {
    //     scrollTrigger: {
    //       trigger: "#salesAmout",
    //       start: "top bottom",
    //       end: "bottom top",
    //       onEnter: ()=> {
    //         setNumb2(100)
    //       }
    //     }
    //   }
    // );
    gsap.to("#chart01", {
      ease: "power4.out",
      duration: 0.5,
      delay: 0,
      scrollTrigger: {
        trigger: "#chart01",
        start: "top center",
        end: "bottom top",
        onEnter: () => {
          setPie01(100)
        },
      },
    })
    gsap.fromTo(
      "#share-label1",
      { right: -30, opacity: 0, delay: 0.8 },
      {
        right: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#chart01",
          start: "top bottom",
          end: "bottom top",
        },
      }
    )
    gsap.fromTo(
      "#share-label2",
      { right: -30, opacity: 0, delay: 0.6 },
      {
        right: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#chart01",
          start: "top bottom",
          end: "bottom top",
        },
      }
    )
    gsap.fromTo(
      "#share-label3",
      { opacity: 0, delay: 0.9 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: "#chart01",
          start: "top bottom",
          end: "bottom top",
        },
      }
    )
    gsap.fromTo(
      "#share-label4",
      { opacity: 0, delay: 0.4 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: "#chart01",
          start: "top bottom",
          end: "bottom top",
        },
      }
    )
    gsap.fromTo(
      "#share-label5",
      { top: -30, opacity: 0, delay: 0.2 },
      {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#chart01",
          start: "top bottom",
          end: "bottom top",
        },
      }
    )
    gsap.to("#chart02", {
      ease: "power4.out",
      duration: 0.5,
      delay: 0,
      scrollTrigger: {
        trigger: "#chart02",
        start: "top center",
        end: "bottom top",
        onEnter: () => {
          setPie02(100)
        },
      },
    })
    gsap.fromTo(
      "#manage-label1",
      { right: -30, opacity: 0, delay: 0.3 },
      {
        right: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#chart02",
          start: "top bottom",
          end: "bottom top",
        },
      }
    )
    gsap.fromTo(
      "#manage-label2",
      { left: -30, opacity: 0, delay: 0.6 },
      {
        left: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#chart02",
          start: "top bottom",
          end: "bottom top",
        },
      }
    )
    gsap.fromTo(
      "#manage-label3",
      { top: -30, opacity: 0, delay: 0.9 },
      {
        top: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: "#chart02",
          start: "top bottom",
          end: "bottom top",
        },
      }
    )
  })
  return (
    <Layout>
      <Seo
        title="ARROWS DATA | Recruit | 株式会社アローズ"
        description="進化する、持続可能なビジネスへの挑戦。"
      />
      <div id="recruit">
        <div className={style.mv}>
          <div className={style.inner}>
            <h1>RECRUIT</h1>
            <h2 className={style.catch}>ARROWS DATA</h2>
          </div>
        </div>
      </div>
      <div className={style.contentsWrap}>
        <section className={`${style.message} ${style.isBg}`}>
          <h2>アローズのいろいろを数値化</h2>
          <p className={style.bottomSpace}>
            アローズではいろいろな製品の企画や営業を行っております。
            <br />
            その結果を分かり易く数値化してみました。
            <br className={style.hiddenPC} />
          </p>
          <div className={style.title}>
            <h3>２０２１年度売上</h3>
          </div>
          <div className={style.sales} id="salesAmout">
            <NumberEasing
              value={numb0}
              speed={500}
              decimals={0}
              ease="quintInOut"
            />
            ,
            <NumberEasing
              value={numb1}
              speed={1000}
              decimals={0}
              ease="quintInOut"
            />
            ,000,000
            {/* <NumberEasing
          value={numb2}
          speed={1500}
          decimals={0}
          ease='quintInOut'/> */}
            <span>円</span>
          </div>
          <div className={style.explain}>
            直近５期連続で増収増益を達成し、右肩あがりで売り上げ・利益が純増しております。
            <br />
            また、取引先も現在では大手・中小合わせて３０社以上にのぼり、市場も拡大しております。今後は市場拡大に合わせ会社規模を大きくし１０年後には２倍以上の２０億を目指していきます。
          </div>
        </section>

        <section className={style.isBg}>
          <div className={style.title}>
            <h3>事業別売り上げシェア</h3>
          </div>
          <div className={style.chartWrap} id="share">
            <div className={style.label1a} id="share-label1">
              <div className={style.labelBox}>
                <div className={style.labelName}>{pieData1[0].title}</div>
                <div className={style.labelNumber}>
                  {pieData1[0].value}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className={style.label2a} id="share-label2">
              <div className={style.labelBox}>
                <div className={style.labelName}>{pieData1[1].title}</div>
                <div className={style.labelNumber}>
                  {pieData1[1].value}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className={style.label3a} id="share-label3">
              <div className={style.labelBox}>
                <div className={style.labelName}>{pieData1[2].title}</div>
                <div className={style.labelNumber}>
                  {pieData1[2].value}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className={style.label4a} id="share-label4">
              <div className={style.labelBox}>
                <div className={style.labelName}>{pieData1[3].title}</div>
                <div className={style.labelNumber}>
                  {pieData1[3].value}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className={style.label5a} id="share-label5">
              <div className={style.labelBox}>
                <div className={style.labelName}>{pieData1[4].title}</div>
                <div className={style.labelNumber}>
                  {pieData1[4].value}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className={style.chart} id="chart01">
              <PieChart
                data={pieData1}
                reveal={pie01}
                lineWidth={38}
                startAngle={-90}
                animate={true}
                animationDuration={1000}
                animationEasing="ease-out"
              >
                <defs>
                  <linearGradient id="gradient1">
                    <stop offset="0%" stopColor="#EA5E4B" />
                    <stop offset="100%" stopColor="#DFABA4" />
                  </linearGradient>
                  <linearGradient id="gradient2">
                    <stop offset="0%" stopColor="#F9A43E" />
                    <stop offset="100%" stopColor="#EEBD82" />
                  </linearGradient>
                  <linearGradient id="gradient3">
                    <stop offset="0%" stopColor="#39A0A4" />
                    <stop offset="100%" stopColor="#5EC0C3" />
                  </linearGradient>
                  <linearGradient id="gradient4">
                    <stop offset="0%" stopColor="#4F80CB" />
                    <stop offset="100%" stopColor="#3963A4" />
                  </linearGradient>
                  <linearGradient id="gradient5">
                    <stop offset="0%" stopColor="#DE46BA" />
                    <stop offset="100%" stopColor="#A4398B" />
                  </linearGradient>
                </defs>
              </PieChart>
            </div>
          </div>
          {/* <div className={style.explain}>
        創業当初からの主軸のアパレル事業が６割を占めていますが、直近の３年ではEC事業が120％の伸び率を有しており、今後の売り上げに期待。家電事業はコンサルティング業務がメインのためテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
        </div> */}
        </section>

        <section className={style.isBg}>
          <div className={style.title}>
            <h3>総務・管理</h3>
          </div>
          <div className={style.chartWrap}>
            <div className={style.label1} id="manage-label1">
              <div className={style.labelBox}>
                <div className={style.labelName}>{pieData2[0].title}</div>
                <div className={style.labelNumber}>
                  {pieData2[0].value}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className={style.label2} id="manage-label2">
              <div className={style.labelBox}>
                <div className={style.labelName}>{pieData2[1].title}</div>
                <div className={style.labelNumber}>
                  {pieData2[1].value}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className={style.label3} id="manage-label3">
              <div className={style.labelBox}>
                <div className={style.labelName}>{pieData2[2].title}</div>
                <div className={style.labelNumber}>
                  {pieData2[2].value}
                  <span>%</span>
                </div>
              </div>
            </div>
            <div className={style.chart} id="chart02">
              <PieChart
                data={pieData2}
                reveal={pie02}
                lineWidth={38}
                startAngle={-90}
                animate={true}
                animationDuration={1000}
                animationEasing="ease-out"
              >
                <defs>
                  <linearGradient id="gradient1">
                    <stop offset="0%" stopColor="#EA5E4B" />
                    <stop offset="100%" stopColor="#DFABA4" />
                  </linearGradient>
                  <linearGradient id="gradient2">
                    <stop offset="0%" stopColor="#F9A43E" />
                    <stop offset="100%" stopColor="#EEBD82" />
                  </linearGradient>
                  <linearGradient id="gradient3">
                    <stop offset="0%" stopColor="#39A0A4" />
                    <stop offset="100%" stopColor="#5EC0C3" />
                  </linearGradient>
                </defs>
              </PieChart>
            </div>
          </div>
          {/* <div className={style.explain}>
        創業当初からの主軸のアパレル事業が６割を占めていますが、直近の３年ではEC事業が120％の伸び率を有しており、今後の売り上げに期待。家電事業はコンサルティング業務がメインのためテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
        </div> */}

<div className={style.entryBox}>
            <h3>
              アローズの募集要項・
              <br className={style.hiddenPC} />
              エントリーはこちら
            </h3>
            <div className={style.entryBoxInner}>
              <div className={style.rikunavi}>
                <div className={style.bnrImg}>
                  <StaticImage
                    src="../images/recruit-bnr01.png"
                    alt="リクナビ"
                    quality={90}
                    placeholder="dominantColor"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    layout="fixed"
                    width={199}
                    height={44}
                  />
                </div>
                <a href="/inquiry" className={`${style.toDetail} pl_slide`}>
                  リクナビからエントリー
                </a>
              </div>
              <div className={style.mynavi}>
                <div className={style.bnrImg}>
                  <StaticImage
                    src="../images/recruit-bnr02.png"
                    alt="マイナビ"
                    quality={90}
                    placeholder="dominantColor"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    layout="fixed"
                    width={172}
                    height={41}
                  />
                </div>
                <a href="/inquiry" className={`${style.toDetail} pl_slide`}>
                  マイナビからエントリー
                </a>
              </div>
            </div>
          </div>


        </section>
      </div>

      <div className={`footNav`}>
        <Link to="/mind" className={`mind`}>
          <p className={`mtM`}>MIND</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/service" className={`service`}>
          <p className={`mtM`}>SERVICE</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/about" className={`about`}>
          <p className={`mtM`}>ABOUT</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
      </div>
    </Layout>
  )
}

export default Arrowsdata
